import React from "react";
import "../../css/main_pages.css";

//import { Link } from "react-router-dom";
import Navbar from "../navbar/Navbar";
import Footer from "../footer/Footer";

export default function Term() {
  return (
    <section className="term-page">
      <Navbar />
      <div className="container">
        <h2>
          <strong>Terms and <span> Conditions</span></strong>
        </h2>






        <h3>Welcome to SONGCOSMOS.com</h3>
          <p>The all-in-one music platform for music creators and facilitators. By becoming a member of songcosmos.com, you agree to be bound by the following terms and conditions:</p>
          <h3>1. Eligibility:</h3>
          <p>To become a member of songcosmos.com, you must be at least 18 years old or have the consent of a parent or legal guardian. You must also provide accurate and complete information during the registration process.</p>
          <h3>2. Membership:</h3>
          <p>songcosmos.com offers various membership plans, including  Lifetime Silver, Gold, and Platinum. By becoming a member, you have access to all the features of the site, including the ability to create and share music, collaborate with other members, and access exclusive discounts on pro audio gear from the PEARLBAY Music Store.</p>
          <h3>3. Account Security:</h3>
          <p>You are responsible for maintaining the confidentiality of your login credentials, including your username and password. You agree not to share your account details with anyone else or permit anyone else to use your account.</p>
          <h3>4. Use of Site:</h3>
          <p>By becoming a member of songcosmos.com, you agree to use the site only for lawful purposes. You agree not to use the site for any unlawful or malicious activity, including but not limited to hacking, spamming, or spreading viruses.</p>
          <h3>5. Intellectual Property:</h3>
          <p>The content on songsosmos.com, including but not limited to music, artwork, and trademarks, is the intellectual property of songcosmos.com and its partners. You agree not to copy, reproduce, distribute, or otherwise use any content from the site without the express written consent of songcosmos.com.</p>
          <h3>6. Termination:</h3>
          <p>songcosmos.com reserves the right to terminate your membership at any time and for any reason, without notice. We also reserve the right to modify or discontinue the site or any of its features without notice.</p>
          <h3>7. Profit-Sharing:</h3>
          <p>As a member of songcosmos.com, you are eligible for a minimum of 15% annual profit sharing equally. An audited financial report will be shared with the public for each financial year, and members will be receiving a portion of their share defined by the "SONGCOSMOS annual profit share terms and conditions"</p>
          <h3>8. Disclaimer:</h3>
          <p>songcosmos.com is not responsible for any errors or omissions on the site, including but not limited to inaccuracies in the content or pricing information. We do not guarantee that the site will be available at all times or that it will be free from errors or interruptions.</p>
          <h3>9. Limitation of Liability:</h3>
          <p>songcosmos.com is not liable for any direct, indirect, incidental, or consequential damages that may result from your use of the site or any content on the site, including but not limited to loss of data or profits.</p>
          <h3>10. Governing Law:</h3>
          <p>These terms and conditions are governed by the laws of the country in which songcosmos.com is registered. Any dispute arising from your use of the site or these terms and conditions will be resolved in accordance with the laws of that country.</p>
          <h3>11. Modification:</h3>
          <p>songcosmos.com reserves the right to modify these terms and conditions at any time, without notice. It is your responsibility to review these terms and conditions periodically to stay informed of any changes.</p>
















        <h3 style={{textAlign:"center",marginTop:"100px"}}>Terms and Conditions for Music Distribution</h3>

        <h3>
          1. Introduction
        </h3>
        <p>
        These Terms and Conditions govern the use of our multi-distribution platform for music distribution. By using our service, you agree to comply with and be bound by these Terms and Conditions, which constitute a legally binding agreement between you and our company.
        </p>

        <h3>
        2. Eligibility
        </h3>

        <p>
        Our music distribution platform is available only to individuals or companies that own the rights to the music they wish to distribute. By using our service, you represent and warrant that you have the necessary rights and permissions to distribute the music you submit through our platform.
        </p>

        <h3>
        3. Distribution
        </h3>


        <p>
        Our multi-distribution platform allows you to distribute your music to over 200 platforms in one click. We do not guarantee that your music will be accepted by all platforms, and we reserve the right to remove any content that violates the terms and conditions of any of the platforms we distribute to. We also reserve the right to modify the list of platforms we distribute to at any time without prior notice.
        </p>

        <h3>
        4. Fees
        </h3>

        <p>
        We charge a membership fee for our music distribution services, which varies depending on the membership plan you choose. You can view our current pricing on our website. We reserve the right to modify our pricing at any time without prior notice.
        </p>

        <h3>
        5. Payment
        </h3>


        <p>
        Payment for our services is due at the time of membership purchase. We accept payment by credit card, PayPal or other payment methods as specified on our website. All payments are final and non-refundable, except as required by law.
        </p>

        <h3>
        6. Representations and Warranties
        </h3>


        <p>
        You represent and warrant that:

        <ul>
          <li>a. You own or control all rights to the music you submit for distribution.</li>
          <li>b. You have obtained all necessary licenses and permissions for any samples, loops, or other elements included in your music.</li>
          <li>c. Your music does not infringe on the rights of any third party, including but not limited to the copyright, trademark, or privacy rights.</li>
          <li>d. Your music does not contain any defamatory, obscene, or illegal content.</li>
          <li>e. You will comply with all applicable laws and regulations in connection with your use of our service.</li>
        </ul>

        
        </p>

        <h3>
        7. Indemnification
        </h3>


        <p>
        You agree to indemnify and hold us harmless from any and all claims, damages, liabilities, costs, and expenses (including reasonable attorneys' fees) arising out of or in connection with your use of our service, including but not limited to any claims of infringement of third party rights.
        </p>

        <h3>
        8. Limitation of Liability
        </h3>


        <p>
        Our liability for any claim arising out of or in connection with your use of our service is limited to the amount you paid for our service. We are not liable for any indirect, incidental, special, or consequential damages arising out of or in connection with your use of our service.
        </p>

        <h3>
        9. Termination
        </h3>


        <p>
        We reserve the right to terminate your access to our service at any time for any reason that violates our terms and conditions, without prior notice. Upon termination, you will no longer have access to our service or any content submitted through our service.
        </p>

        <h3>
        10. Governing Law
        </h3>


        <p>
        These Terms and Conditions are governed by and construed in accordance with the laws of the jurisdiction in which our company is located, without giving effect to any principles of conflicts of law. Any legal action arising out of or in connection with these Terms and Conditions must be brought in the courts of that jurisdiction.
        </p>

        <h3>
        11. Entire Agreement
        </h3>


        <p>
        These Terms and Conditions constitute the entire agreement between you and our company with respect to the subject matter hereof and supersede all prior agreements and understandings, whether written or oral. No amendment or modification of these Terms and Conditions will be binding unless in writing and signed by both parties.
        </p>



        <h3>Indemnification Clause for Music Distribution in Vydia through Songcosmos</h3>
<p>In consideration of using the Songcosmos music distribution service powered by Vydia, the user agrees to indemnify and hold harmless Songcosmos, Vydia, its officers, directors, employees, agents, affiliates, and licensors from any claims, damages, losses, liabilities, costs, and expenses arising out of or related to the user's use of the service.</p>
<p>This indemnification includes but is not limited to any claims related to the user's infringement of any intellectual property rights or violation of any applicable law or regulation in connection with the distribution of music through the Vydia platform. The user agrees to pay any damages, costs, or attorneys' fees awarded against Songcosmos or Vydia in any such claim, and to cooperate with Songcosmos and Vydia in the defense of any such claim.</p>
<p>The user acknowledges that Songcosmos and Vydia are not responsible for any loss of data or money resulting from the use of the service, and agrees that Songcosmos and Vydia shall not be liable for any such loss, regardless of whether the loss was caused by Songcosmos or Vydia's negligence or otherwise. However, Songcosmos will take all reasonable care to eradicate or minimize any faults within our service. The user agrees to take all necessary precautions to protect their data and financial information, and to report any suspected unauthorized access to their account to Songcosmos and Vydia immediately.</p>
<p>By using the Songcosmos music distribution service powered by Vydia, the user agrees to be bound by Songcosmos and Vydia's terms and conditions, which can be found on www.vydia.com.</p>




        <h3 style={{textAlign:"center",marginTop:"100px"}}>Terms and Conditions for Membership Benefits</h3>

        <h3>1. Eligibility:</h3>
        <p>Members who have distributed or published a minimum of 5 songs and have fully completed their profile are eligible for membership benefits according to their tier. (Exclusion applied)</p>
        <h3>2. Tiers:</h3>
        <p>There are 5 membership tiers available - Classic, Bronze, Silver, Gold and Platinum. The benefits available to members will vary depending on their tier.</p>
        <h3>3. Benefits:</h3>
        <p>Membership benefits may include access to exclusive content, discounts on merchandise or services, early access to new releases, exclusive royalty splits and more. Each membership benefit is updated periodically and published on our website <a href="www.songcosmos.com">www.songcosmos.com</a> available to check at any time. The level of exclusive discount may be subject to change annually.</p>
        <h3>4. Membership Tiers:</h3>
        <p>Members in the Silver tier will have access to basic benefits, which may include access to some exclusive content, limited discounts on merchandise or services, and early access to some new releases.Gold and Platinum membership has higher benefits including better splits on distribution and publishing revenue share.</p>
        <h3>5. Pro-Audio Discounts:</h3>
        <p>Members can receive a minimum of 2.5% discount on pro audio equipment purchases at <a href="www.pearlbaymusicstore.com">www.pearlbaymusicstore.com</a>. All members are eligible to receive discounts according to their membership category immediately after joining as a SONGCOSMOS member subject to normal terms and conditions of the use of the site. All purchases regardless of the membership category are subject to terms imposed by the Pearlbay Music Store such as delivery time, dispatch time and availability.</p>
        <h3>6. Annual Profit Share:</h3>
        <p>To qualify for the annual profit share from Songcosmos, members must have an active and valid membership for at least one year at the time of profit distribution. Additionally, to be eligible for profit sharing, members must have published or distributed a minimum of five songs during the previous financial year (April to March). The payment will be made to the bank account specified in the member's profile, and the member is solely responsible for ensuring the accuracy of the provided account information or PayPal account. Songcosmos will not be responsible for any payment made to an incorrect account due to incorrect information provided by the member. The payment made to the member's account will be subject to interbank and international transfer fees or PayPal transaction fees, as well as exchange rates defined by banks or PayPal, which are beyond the control of Songcosmos or its partners.</p>
        <h3>7. Limitations:</h3>
        <p>Membership benefits are subject to availability and may be limited by factors such as geographic location or the type of content being released.</p>
        <h3>8. Termination:</h3>
        <p>Membership benefits may be terminated if a member violates the terms and conditions or engages in any fraudulent activity. Songcosmos has the right to investigate and determine the fair and lawful usage of the membership accounts.</p>
        <h3>9. Modification:</h3>
        <p>These terms and conditions are subject to change at any time, and changes will be effective immediately upon posting on the website.</p>
        <h3>10. Acceptance:</h3>
        <p>By applying for membership, members agree to these terms and conditions and agree to abide by them.</p>












        <h3 style={{textAlign:"center",marginTop:"100px"}}>Terms and Conditions for Mix and Master Service Provided by Songcosmos Facilitated by Pearlbay Music</h3>


        <h3>1. Overview:</h3>
<p>The Mix and Master service offered by Songcosmos and facilitated by Pearlbay Music is provided to members who upload their tracks for mixing and mastering. Pearlbay Music will confirm the details of the project with the member before commencing work.</p>
<h3>2. Service Description:</h3>
<p>The Mix and Master service offered by Songcosmos involves the mixing and mastering of a member's music track. The service includes adjusting levels, equalization, compression, and other techniques to achieve a polished, professional sound.</p>
<h3>3. Payment:</h3>
<p>Following any free allowance granted based on their membership category, members will incur a fee for utilizing the Mix and Master service. This fee will be established by Pearlbay Music and communicated to the member before any work begins. After any membership discounts of up to 25%, half of the payment is required before work commences and the remaining balance is due in full before the finished track is released to the member.</p>
<h3>4. Turnaround Time:</h3>
<p>Pearlbay Music will provide an estimated turnaround time for the Mix and Master service based on the complexity of the project. However, the actual turnaround time may vary depending on the workload of Pearlbay Music and the complexity of the project.</p>
<h3>5. Revisions:</h3>
<p>The member will have the opportunity to provide feedback and request revisions to the Mix and Master service. Pearlbay Music will provide one round of revisions free of charge. Any additional revisions will be subject to an additional fee.</p>
<h3>6. Ownership and Rights:</h3>
<p>The member retains all ownership and rights to their music track. However, by using the Mix and Master service, the member grants Pearlbay Music a non-exclusive license to use the track for promotional purposes.</p>
<h3>7. Disclaimer of Liability:</h3>
<p>Pearlbay Music and Songcosmos are not liable for any damages, losses, or expenses incurred by the member as a result of using the Mix and Master service. The member assumes all risks associated with the service.</p>
<h3>8. Termination:</h3>
<p>Pearlbay Music may terminate the Mix and Master service at any time for any reason. If the service is terminated before completion, the member will be refunded for any work not yet completed.</p>
<h3>9. Governing Law:</h3>
<p>These terms and conditions are governed by the laws of the jurisdiction in which Pearlbay Music operates.</p>
<h3>10. Amendments:</h3>
<p>These terms and conditions may be amended by Pearlbay Music at any time. The member will be notified of any changes to the terms and conditions.</p>










      
<h3 style={{textAlign:"center",marginTop:"100px"}}>Copyright Licensing Terms and Conditions for Sri Lankan Music Catalog</h3>

<h3>1. Introduction</h3>
<p>These terms and conditions (the “Agreement”) govern the use of the Sri Lankan Music Catalog (the “Catalog”) published by Pearlbay Publishing (“Publisher”). By using the Catalog, you agree to be bound by these terms and conditions. If you do not agree with these terms and conditions, you must not use the Catalog.</p>
<h3>2. License</h3>
<p>2.1 The Publisher hereby grants to you a non-exclusive, non-transferable license to use the Catalog solely for the purpose of licensing copyrights for mechanical, synchronization, digital and online distribution.</p>
<p>2.2 This license is subject to payment of the applicable licensing fee, which shall be determined by the Publisher and communicated to you at the time of the license request.</p>
<p>2.3 The license is limited to the Sri Lankan territory and cannot be sublicensed or used for any other purpose without the express written consent of the Publisher.</p>
<h3>3. Ownership and Copyright</h3>
<p>3.1 The Catalog is owned by the Publisher and its Publishing and sub-publishing partners.</p>
<p>3.2 All copyrights, trademarks, and other intellectual property rights in the Catalog shall remain the property of the Publisher and its Publishing and sub-publishing partners.</p>
<p>3.3 All rights not expressly granted under this Agreement are reserved to the Publisher and its Publishing and sub-publishing partners.</p>
<h3>4. Licensing Fee</h3>
<p>4.1 All usage of copyrights from the Catalog should be licensed before using for any mechanical, synchronization, digital and online distribution.</p>
<p>4.2 The licensing fee for using the Catalog shall be determined by the Publisher and communicated to you at the time of the license request.</p>
<p>4.3 Members can receive exclusive discounts on licensing fee depending on their membership categories.</p>
<h3>5. Termination</h3>
<p>5.1 The Publisher may terminate this Agreement immediately if you breach any of its terms and conditions.</p>
<p>5.2 Upon termination of this Agreement, all rights granted to you under this Agreement shall immediately cease.</p>
<h3>6. Disclaimer of Warranties and Limitation of Liability</h3>
<p>6.1 The Catalog is provided “as is” without any warranties or representations, express or implied.</p>
<p>6.2 The Publisher shall not be liable to you for any direct, indirect, incidental, special, or consequential damages arising out of or in connection with this Agreement or your use of the Catalog.</p>
<p>6.3 The Publisher makes no representations or warranties as to the accuracy, completeness, or suitability of the Catalog for any purpose.</p>
<h3>7. Governing Law</h3>
<p>This Agreement shall be governed by and construed in accordance with the laws of Sri Lanka.</p>
<h3>8. Entire Agreement</h3>
<p>This Agreement constitutes the entire agreement between you and the Publisher with respect to the Catalog and supersedes all prior or contemporaneous communications and proposals, whether oral or written, between you and the Publisher.</p>
<h3>9. Amendments</h3>
<p>The Publisher may amend this Agreement at any time by posting the amended terms and conditions on its website. Your continued use of the Catalog after any such amendments shall constitute your acceptance of the amended terms and conditions.</p>








<h3 style={{textAlign:"center",marginTop:"100px"}}>Terms and Conditions for Joining Music Collaborators (Music Buddies) Community a free service provided by SONGCOSMOS for its members</h3>



<h3>Welcome to Music De Edu</h3>
<p>Music De Edu is a resource and advertising space exclusively for education providers in music and audio engineering by Songcosmos. By using our platform, you agree to the following terms and conditions:</p>
<h3>1. Membership</h3>
<p>Education providers in music and audio engineering may become members of Music De Edu. The membership will be subject to approval by Songcosmos. Membership may be terminated by either party with a prior notice of 30 days.</p>
<h3>2. Account creation</h3>
<p>To become a member of Music De Edu, you must create an account on our platform. You agree to provide accurate, current, and complete information during the registration process. You are solely responsible for maintaining the confidentiality of your account information, including your password. You agree to immediately notify Songcosmos of any unauthorized use of your account.</p>
<h3>3. Listing information</h3>
<p>As a member of Music De Edu, you are allowed to list your education programs, courses, and other relevant information related to music and audio engineering education. All information listed must be accurate, up-to-date, and in compliance with all applicable laws and regulations.</p>
<h3>4. Advertising</h3>
<p>Members of Music De Edu are allowed to advertise their education programs and courses. However, all advertisements must comply with all applicable laws and regulations, and must not be false, misleading, or deceptive in any way.</p>
<h3>5. Payments</h3>
<p>Members of Music De Edu are required to pay a membership fee to access our platform. The membership fee will be determined by Songcosmos and may be subject to change from time to time. All payments must be made in accordance with the payment terms specified by Songcosmos.</p>
<h3>6. Termination</h3>
<p>Songcosmos reserves the right to terminate the membership of any member at any time for any reason, including but not limited to a violation of these terms and conditions. In the event of termination, all fees paid by the member will be forfeited.</p>
<h3>7. Limitation of liability</h3>
<p>Songcosmos shall not be liable to any member or any third party for any indirect, incidental, special, or consequential damages arising out of or in connection with the use of our platform. In no event shall Songcosmos be liable for any damages in excess of the membership fees paid by the member.</p>
<h3>8. Indemnification</h3>
<p>Members of Music De Edu agree to indemnify and hold Songcosmos harmless from any and all claims, damages, liabilities, costs, and expenses arising out of or in connection with the member's use of our platform.</p>
<h3>9. Governing law</h3>
<p>These terms and conditions shall be governed by and construed in accordance with the laws of the state of California, without giving effect to any principles of conflicts of law.</p>
<h3>10. Modification</h3>
<p>Songcosmos reserves the right to modify these terms and conditions at any time, with or without notice. It is the member's responsibility to regularly review these terms and conditions to ensure compliance.</p>










<h3 style={{textAlign:"center",marginTop:"100px"}}>Terms and Conditions for Joining Music Collaborators (Music Buddies) Community a free service provided by SONGCOSMOS for its members</h3>


<h3>Terms and Conditions of Music Buddies Community</h3>
<p>When you decide to become a member of SONGCOSMOS's Music Buddies Community, you are agreeing to the terms and conditions below. These terms and conditions create a binding agreement between you and the Music Buddies Community and SONGCOSMOS.</p>
<p>To join the Music Buddies Community, you must be at least 18 years old and able to enter into a legally binding agreement. It is your responsibility to provide accurate information during registration, and providing false information may result in termination of your membership without a refund.</p>
<p>There is no membership fee for joining the Music Buddies Community for members of SONGCOSMOS, and your membership will be valid for either the duration of your life or on an annual basis.</p>
<p>The Music Buddies Community offers a platform for members to collaborate on music projects and find services related to the music industry. Members are responsible for the accuracy and truthfulness of the information they provide, and the Music Collaborators Community does not guarantee the reliability of any information provided by members.</p>
<p>All members of the Music Buddies Community are required to behave professionally and respectfully. Any inappropriate or offensive behavior may result in the termination of your membership. Members must also respect the intellectual property of others and not engage in any infringement or copyright violation.</p>
<p>Members retain ownership of their intellectual property, including music compositions, lyrics, and recordings. By sharing your intellectual property with other members, you are granting them a non-exclusive license to use your intellectual property for the purpose of collaborating on music projects at their own risk.</p>
<p>The Music Buddies Community reserves the right to terminate your membership at any time without prior notice, and you can terminate your membership by providing written notice. No membership fee refunds will be provided.</p>
<p>SONGCOSMOS is not responsible for any damages, losses, or expenses resulting from your use of the platform, including any copyright infringements by other parties. By posting unpublished, not-copyrighted material, you do so at your own risk.</p>
<p>These terms and conditions are governed by the laws of Sri Lanka, and any disputes will be resolved in the courts of Sri Lanka. The Music Buddies Community reserves the right to amend these terms and conditions at any time, and any changes will become effective immediately upon posting. By joining the Music Collaborators Community, you are agreeing to these terms and conditions.</p>




      </div>
      <Footer />
    </section>
  );
}
