import React from "react";
import "../../css/main_pages.css";

//import { Link } from "react-router-dom";
import Navbar from "../navbar/Navbar";
import Footer from "../footer/Footer";

//import about_1_img from "../../image/about-us-1.jpg";
//import about_2_img from "../../image/about-us-2.jpg";
//import about_3_img from "../../image/about-us-3.jpg";

import {Image ,Grid, GridItem} from "@chakra-ui/react";


export default function About (){

    return(

        <section className="about-page">
            <Navbar/>

                <div className="container">

                    <h1  className="title">An <span> introduction </span> to ourselves</h1>

                <div className="text-box-1">
                        <p>Introducing our all-in-one music platform that not only provides music distribution, publishing, pro audio discounts, and collaboration but also ensures that all members receive a fair share of the profits.

                        Our platform is designed to support and empower independent artists and music professionals. We believe that all creators should be fairly compensated for their work, regardless of how well it performs in the market. That's why we've built a unique profit-sharing model that ensures all members receive a portion of the revenue generated by the platform, regardless of how many streams, downloads, or sales their music generates.</p> <br/>

                        <p>As a member of our platform, you'll have access to a suite of powerful tools and services to help you distribute your music to all the major streaming platforms, register your songs with PROs, and collaborate with other professionals in the industry. You'll also be eligible for exclusive discounts on pro audio gear and software to help you achieve the best possible sound quality.</p> <br/>

                        <p>But the real value of our platform lies in our commitment to fairness and transparency. We believe that every artist deserves to be fairly compensated for their hard work, regardless of their level of success in the industry. That's why we've built a platform that shares profits with all members equally, ensuring that everyone has the opportunity to earn a living doing what they love. <br/>

                        Join our platform today and take the first step towards a fairer, more equitable music industry. With our all-in-one music platform, you'll have everything you need to succeed, and the assurance that your hard work will be rewarded, no matter what.</p>
                    </div>








                    <h1 className="title">Our Commitment to <br/> <span> Excellence </span></h1>

                    <div className="text-box-1">
                        <p>At our all-in-one music platform, we are committed to excellence in everything we do. We believe that our members deserve the very best tools and services to help them succeed in the music industry, and we strive to provide them with nothing less. Our commitment to excellence begins with our music distribution service. We work tirelessly to ensure that your music is delivered quickly and efficiently to all the major streaming platforms, so you can start earning revenue as soon as possible. Our platform is constantly updated to stay up-to-date with the latest industry standards, so you can be sure that your music is always in good hands.</p> <br/>

                        <p>When it comes to music publishing, we are equally committed to excellence. We understand that registering your songs with PROs and collecting royalties can be a daunting task, which is why we make it as easy and seamless as possible. We provide our members with expert guidance on copyright and publishing, and we work with the most reputable PROs to ensure that you receive all the royalties you're entitled to.We also offer our members exclusive discounts on pro audio gear and software to help them achieve the best possible sound quality. We believe that great music deserves great production, and we are committed to helping our members achieve their artistic vision.</p> <br/>

                        <p>Finally, our commitment to excellence extends to our collaboration features. We understand the power of collaboration in the music industry, and we provide our members with the tools they need to connect with other professionals, share ideas, and work on projects together. We believe that by working together, we can create something truly exceptional. short, our commitment to excellence is at the heart of everything we do. We believe that by providing our members with the very best tools and services, we can help them achieve their full potential in the music industry. Join our all-in-one music platform today and experience excellence for yourself.</p>
                    </div>


                    <Grid templateColumns={{ base: "1fr", lg: "repeat(2, 1fr)" }} gap={4} className="team-row" display={"none"}>
                        <GridItem className="col-6" colSpan={{ base: 2, lg: 1 }}>

                           
                              <Image  alt="about us" className="about-us-team"/>
                      

                        </GridItem>
                        <GridItem className="col-6  about-us-team-text" colSpan={{ base: 2, lg: 1 }}>

                            <h1>Our songcosmos <span> familly</span></h1>
                            <p>
                            We are committed to upholding our most cherished values for every client, regardless of their expectations.
                            Whether you are a student, an artist, a business partner, or a holidaymaker, we want you to enjoy the abundance of love we have at Pearlbay. Pearlbay welcomes you with a warm Ayubowan and promises to deliver world-class services from the moment you enter Pearlbay.
                            </p>

                            <p>
                            Our dedication and positive thinking have led us to be one of the most recognised brands in Sri Lanka, with an international presence in the UK. We are looking forward to the challenges on our road to becoming one of the most successful companies in Sri Lanka, servicing sectors beyond the music industry.
                            </p>
                        </GridItem>
                    </Grid>



                    <Grid templateColumns={{ base: "1fr", lg: "repeat(2, 1fr)" }} gap={4} className="owner-row" display={"none"}>
                      
                        <GridItem className="col-6  about-us-owner-text" colSpan={{ base: 2, lg: 1 }}>

                            <h1><span> LEADERSHIP</span> AND GOVERNANCE</h1>
                            <p>
                            Our core values are to provide our clients with creative, rigorous, and sustainable environments whilst delivering our services with an artistically ambitious, industry-focused, and quality-driven way of thinking. Our progress is built on principles such as exceptional learning, artistic discoveries, and collaborations. Achieving performance constantly and confidently is our core objective. 

                            <br/> <br/>

                            We know the future is challenging, and we take it seriously. And we want to adapt and progress to a future that benefits all, not just corporations. We shape our strategies and structure our organization to deliver value to our clients.<br/> <br/>

                            My team and I will continue to build a place that offers an exceptional learning experience, a center for creative discovery that embeds collaboration within the music industry and beyond.<br/>

                            It is my ambition to lead a team with quality and integrity, bringing in passion and skills to create more marvelous turning points for Pearlbay’s journey of endless possibilities. We want to be an inspiration for future entrepreneurs to do well and inspire change for a better world.
                            </p>
                        </GridItem>

                        <GridItem className="col-6" colSpan={{ base: 2, lg: 1 }}>
                            <Image src={""} alt="about us" className="about-us-owner"/>
                        </GridItem>

                    </Grid>


                </div>

            <Footer/>
        </section>
    )
}