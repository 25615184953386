import React from "react";
import "../../css/main_pages.css";

//import { Link } from "react-router-dom";
import Navbar from "../navbar/Navbar";
import Footer from "../footer/Footer";

export default function Privacy() {

 
  return (
    <section className="term-page">
      <Navbar />
      <div className="container">
        <h2>
          <strong>
            Privacy &<span> Policy</span>
          </strong>
        </h2>

        

        <h3>Privacy Policy for www.songcosmos.com</h3>
<p>At www.songcosmos.com, we are committed to protecting the privacy of our users. This Privacy Policy outlines how we collect, use, disclose, and protect your personal information when you use our website. By using our website, you consent to the collection, use, and disclosure of your personal information as described in this Privacy Policy.</p>
<h3>1. Information We Collect</h3>
<p>We may collect personal information from you when you visit our website, including:</p>
<ul>
  <li>Information you provide when you register for an account, such as your name, email address, and other contact information;</li>
  <li>Information you provide when you fill out forms, such as feedback forms or survey questions;</li>
  <li>Information you provide when you make a purchase, such as your name, billing address, and credit card information;</li>
  <li>Information you provide when you participate in forums or chat rooms;</li>
  <li>Information about your computer or mobile device, such as your IP address, operating system, and browser type; and</li>
  <li>Information about your use of our website, such as the pages you visit and the links you click.</li>
</ul>
<h3>2. Use of Information</h3>
<p>We may use your personal information for the following purposes:</p>
<ul>
  <li>To provide and improve our services;</li>
  <li>To communicate with you about our services, such as updates or promotional offers;</li>
  <li>To process transactions and provide customer support;</li>
  <li>To analyze how our website is used and improve our website;</li>
  <li>To prevent fraud and unauthorized access to our website; and</li>
  <li>To comply with legal obligations.</li>
</ul>
<h3>3. Disclosure of Information</h3>
<p>We may share your personal information with third-party service providers who perform services on our behalf, such as payment processors or marketing agencies. We may also share your personal information if we are required to do so by law or if we believe that such action is necessary to protect our rights or the rights of others.</p>
<h3>4. Security</h3>
<p>We take reasonable measures to protect your personal information from unauthorized access, use, or disclosure. However, no security measure can guarantee the security of your personal information.</p>
<h3>5. Cookies</h3>
<p>Our website uses cookies to improve your experience and to provide personalized content and advertising. You can choose to disable cookies in your browser settings, but this may limit your ability to use some features of our website.</p>
<h3>6. Links to Third-Party Websites</h3>
<p>Our website may contain links to third-party websites. We are not responsible for the privacy practices or content of these websites. We encourage you to review the privacy policies of these websites before providing them with any personal information.</p>
<h3>7. Children</h3>
<p>Our website is not intended for children under the age of 13. We do not knowingly collect personal information from children under the age of 13. If you are a parent or guardian and believe that your child has provided us with personal information, please contact us immediately.</p>

<h3>8. Changes to this Privacy Policy</h3>
<p>We reserve the right to update this Privacy Policy at any time. We will notify you of any changes by posting the new Privacy Policy on our website. Your continued use of our website after the effective date of the revised Privacy Policy constitutes your acceptance of the terms of the revised Privacy Policy.</p>

<h3>9. Contact Us</h3>
<p>If you have any questions or concerns about this Privacy Policy or our practices, please contact us at privacy@songcosmos.com</p>









      </div>
      <Footer />
    </section>
  );
}
